































































import { useCiService } from '@/lib/Ci';
import { DivisionDoc, DivisionSnapshot } from '@/lib/models/DivisionModel';
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from '@vue/composition-api';
import Box from '../box/Box.vue';
import SetupDivisionRow from './SetupDivisionRow.vue';

export default defineComponent({
  components: { Box, SetupDivisionRow },
  props: {
    divisionSnapshot: {
      type: Object as PropType<DivisionSnapshot>,
      required: true,
    },
  },
  setup(props) {
    const divisionList = useCiService('DivisionListService');
    const data = computed(() => props.divisionSnapshot.data() as DivisionDoc);
    const lines = computed(() => data.value.lines);
    const inputEditNameEl = ref<any>();

    const editName = ref(false);
    const editNameText = ref<string>('');

    const createLineText = ref<string>('');

    const attempUpdateName = () => {
      editNameText.value = data.value.name;
      editName.value = true;
    };

    const cancelUpdateName = () => {
      editNameText.value = '';
      editName.value = false;
    };

    const updateName = () => {
      const name = editNameText.value.trim();
      if (name.length) divisionList.updateName(props.divisionSnapshot.id, name);
      editName.value = false;
    };

    const remove = () => {
      divisionList.remove(props.divisionSnapshot.id);
    };

    const createLine = () => {
      const content = createLineText.value.trim();
      if (content.length)
        divisionList.createLine(props.divisionSnapshot.id, content);
      createLineText.value = '';
    };

    watch(editName, (show) => {
      if (show) {
        setTimeout(() => inputEditNameEl.value.$refs.input.focus());
      }
    });

    const s = {
      data,
      editName,
      editNameText,
      attempUpdateName,
      cancelUpdateName,
      updateName,
      remove,
      lines,
      createLine,
      createLineText,
      inputEditNameEl,
    };
    return s;
  },
});
