



























import { useCiService } from '@/lib/Ci';
import { DivisionSnapshot } from '@/lib/models/DivisionModel';
import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import InlineEdit from '../InlineEdit.vue';

export default defineComponent({
  components: { InlineEdit },
  props: {
    index: {
      type: Number,
      required: true,
    },
    line: {
      type: String,
      required: true,
    },
    divisionSnapshot: {
      type: Object as PropType<DivisionSnapshot>,
      required: true,
    },
  },
  setup(props) {
    const divisionList = useCiService('DivisionListService');
    const inputEdit = ref<any>();

    const edit = ref(false);

    const submitEdit = (str: string) => {
      const content = str.trim();
      const id = props.divisionSnapshot.id;
      if (content.length) divisionList.updateLine(id, props.index, content);
    };

    const remove = () => {
      const id = props.divisionSnapshot.id;
      divisionList.removeLine(id, props.index);
    };

    const s = {
      edit,
      submitEdit,
      remove,
      inputEdit,
    };
    return s;
  },
});
