





















import SetupDivision from '@/components/setup-division/SetupDivision.vue';
import SetupDivisionCreate from '@/components/setup-division/SetupDivisionCreate.vue';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
} from '@vue/composition-api';
import { useCiService } from '@/lib/Ci';
import { Subscription } from 'rxjs';
import { subscribeTo } from '@/lib/Util';

export default defineComponent({
  components: { SetupDivisionCreate, SetupDivision },
  setup() {
    const division = useCiService('DivisionListService');
    const divisionSnapshots = subscribeTo([], division.state.snapshots);

    let subDivisions: Subscription;
    onMounted(() => (subDivisions = division.listen().subscribe()));
    onBeforeUnmount(() => subDivisions.unsubscribe());

    const s = {
      divisionSnapshots,
    };
    return s;
  },
});
