

























import { useCiService } from '@/lib/Ci';
import { DivisionDoc, DivisionSnapshot } from '@/lib/models/DivisionModel';
import { subscribeTo } from '@/lib/Util';
import { computed, defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const divisionList = useCiService('DivisionListService');

    const diviSnapshots = subscribeTo<DivisionSnapshot[]>(
      [],
      divisionList.state.snapshots,
    );

    const text = ref<string>('');

    const bookmarks = computed(() => {
      return diviSnapshots.value.map((snap) => {
        return {
          value: snap.id,
          label: (snap.data() as DivisionDoc).name,
        };
      });
    });

    const create = () => {
      const name = text.value.trim();
      if (name.length) divisionList.create(name);
      text.value = '';
    };

    const s = {
      create,
      text,
      bookmarks,
    };
    return s;
  },
});
